"use client"
  import __i18nConfig from '@next-translate-root/i18n'
  import AppDirI18nProvider from 'next-translate/AppDirI18nProvider'
  import { useSearchParams as __useSearchParams, useParams as __useParams } from 'next/navigation'
  import { use as __use, Suspense as __Suspense } from 'react'
  import __loadNamespaces from 'next-translate/loadNamespaces'

  ; // Error boundaries must be Client Components
import { ErrorPage } from '@features/common.containers/ErrorPage';
export interface ErrorProps {
    reset: () => void;
}
export const Error: React.FC<ErrorProps> = ({ reset }) => {
    return <ErrorPage reset={reset}/>;
};
const __Next_Translate__Page__1961a680258__ = Error;


  export default function __Next_Translate_new__1961a680258__(props) {
    const searchParams = __useSearchParams()
    const params = __useParams()
    const detectedLang = params.lang ?? searchParams.get('lang')

    if (detectedLang === 'favicon.ico') return <__Next_Translate__Page__1961a680258__ {...props} />

    if (globalThis.__NEXT_TRANSLATE__ && !detectedLang) return <__Next_Translate__Page__1961a680258__ {...props} />

    const lang = detectedLang ?? __i18nConfig.defaultLocale
    const config = {
      ...__i18nConfig,
      locale: lang,
      loaderName: 'client /error',
      pathname: '/',
    }

    return (
      <__Suspense fallback={null}>
        <__Next_Translate__child__1961a680258__ 
          {...props} 
          config={config} 
          promise={__loadNamespaces({ ...config, loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})), })}
         />
      </__Suspense>
    )
  }

  function __Next_Translate__child__1961a680258__({ promise, config, ...props }) {
    const { __lang, __namespaces } = __use(promise);
    const oldNamespaces = globalThis.__NEXT_TRANSLATE__?.namespaces ?? {};
    globalThis.__NEXT_TRANSLATE__ = { lang: __lang, namespaces: { ...oldNamespaces, ...__namespaces }, config };
    return <__Next_Translate__Page__1961a680258__ {...props} />;
  }
